import { A, cache, createAsync, useParams } from "@solidjs/router";
import { Accessor, For, onCleanup, onMount, Suspense } from "solid-js";
import { successTick } from "~/assets/assets";
import { getProcurementProformaInvoiceDetailRouteData } from "~/server/data/procurement_proforma_Invoice_detail_route_data";
import { ProcurementProformaInvoiceDetail } from "~/server/types/order";
import { ButtonRegular } from "~/widgets/button";
import { Divider } from "~/widgets/divider";
import HubbleImage from "~/widgets/hubble_image";
import { DottedLoader } from "~/widgets/loader";
import {
  convertDateFormatFromYYYYMMDDToDDMMYYYY,
  formatDateToDDMMMMYYYY,
} from "~/utils/date";
import { useProcurement } from "~/context/procurement";
import { goBack } from "~/shared_states/modal";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

const getProcurementProformaInvoiceDetailRouteData$C = cache(
  getProcurementProformaInvoiceDetailRouteData,
  "procurement_proforma_invoice_detail"
);

export default function ProformaInvoiceDetail() {
  const params = useParams();
  const { clearCart } = useProcurement();
  const routeData: Accessor<ProcurementProformaInvoiceDetail | undefined> =
    createAsync<ProcurementProformaInvoiceDetail>(() => {
      return getProcurementProformaInvoiceDetailRouteData$C({
        proformaInvoiceId: params.proformaInvoiceId,
      });
    });

  onCleanup(() => {
    clearCart();
  });

  return (
    <Suspense
      fallback={
        <div class="flex w-full items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="w-full  md:mx-auto md:max-w-[1136px]">
        <Spacer height={60} />
        <div class="flex w-full flex-col items-end justify-end  ">
          <div
            class=" flex h-9 w-9 cursor-pointer items-center justify-center rounded-[100%] border bg-white"
            style={{ "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.02)" }}
            onClick={() => {
              goBack();
            }}
          >
            <PhosphorIcon name="x" fontSize={20} class="text-baseTertiary" />
          </div>
        </div>
        <div class="flex w-full flex-col items-center justify-center">
          <div class="mb-6 h-20 w-20">
            <HubbleImage src={successTick} alt="" class="" />
          </div>
          <div class="text-mediumBold text-successDark">
            Proforma Invoice generated
          </div>
          <div class="my-1 text-h2">Sent to your email</div>
          <div class="text-f12 text-textNormal">
            {routeData()?.date
              ? formatDateToDDMMMMYYYY(new Date(routeData()?.date!))
              : ""}{" "}
            | ID: {decodeURIComponent(routeData()?.proformaInvoiceId ?? "")}
          </div>
          <A
            href={
              "https://receipts.dev.myhubble.money/proforma-invoice/" +
              routeData()?.id
            }
            target="_blank"
          >
            <div class="mt-7 text-mediumBold">
              <ButtonRegular
                onClick={() => {}}
                isInitiallyEnabled={true}
                class="rounded-[16px]"
                isRectangular={true}
              >
                View Proforma Invoice
              </ButtonRegular>
            </div>
          </A>
          <div class="w-full max-w-[480px]">{steps()}</div>
        </div>
      </div>
    </Suspense>
  );

  function steps() {
    const nextSteps = [
      "Please make the payment by NEFT, IMPS or RTGS to the account number given on the Performa Invoice.",
      "Once payment is confirmed, we’ll add funds to your Squid Wallet.",
      "Go to the 'Performa Invoice' tab in the main menu to revisit this PI and complete the order by using Squid Wallet balance.",
    ];
    return (
      <div class="mt-10 flex w-full  flex-col items-start justify-start">
        <div class="mb-5 flex w-full items-center">
          <Divider />
          <div class="text-nowrap px-2 text-smallBold text-textNormal">
            NEXT STEPS
          </div>
          <Divider />
        </div>
        <For each={nextSteps}>
          {(step) => (
            <div class="mb-3 flex flex-row">
              <div class="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-baseTertiaryMedium text-smallBold text-textDark">
                {nextSteps.indexOf(step) + 1}
              </div>
              <div class="ml-2 text-medium text-textDark">{step}</div>
            </div>
          )}
        </For>
      </div>
    );
  }
}
